.bars {
    width: 2.5em;
    height: 1.5em;
    background: transparent;
    position: relative;
}

.net{
    display:flex;
    justify-content: center;
}

.bars>span {
    width: 0.4em;
    position: absolute;
    bottom: 0em;
    margin: 0.5px;
    border-radius: 0.25em;
}

.bars>span:nth-child(4) {
    height: 1.2em;
    right: 0.1em;
}

.bars>span:nth-child(3) {
    height: 0.9em;
    right: 0.8em;
}

.bars>span:nth-child(2) {
    height: 0.6em;
    right: 1.5em;
}

.bars>span:nth-child(1) {
    height: 0.3em;
    right: 2.2em;
}

.bars[data-bars="4"] > span {
    background: #0f1;
  }

.bars[data-bars="3"] > span {
    background: #0af;
  }


.bars[data-bars="2"] > span {
    background: #FFB03D;
  }

.bars[data-bars="1"] > span {
    background: #F00;
  }

.bars[data-bars="1"]>span:nth-child(2),
.bars[data-bars="1"]>span:nth-child(3),
.bars[data-bars="1"]>span:nth-child(4) {
    background: #ddd;
}

.bars[data-bars="2"]>span:nth-child(3),
.bars[data-bars="2"] span:nth-child(4) {
    background: #ddd;
}

.bars[data-bars="3"]>span:nth-child(4) {
    background: #ddd;
}