body {
  font-size: 0.8rem;
}
* {
  box-sizing: border-box;
}
.link-click:hover {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}
.radius-10 {
  border-radius: 10px;
}

.pagination-border {
  padding: 2px;
  border: 1px solid rgb(204, 203, 203);
  border-collapse: collapse;
}
.page-item-size {
  text-align: center;
  width: 50px;
}
